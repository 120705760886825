<template>
  <div class="credit_wrap">
    <el-row type="flex" class="credit_head_container" justify="space-between" align="middle">
      <el-col :span="12">
        <h2 class="credit_head_title">
          Credit Score <br> Powere by
        </h2>
      </el-col>
      <el-col :span="12">
        <ul class="redit_head_icon_region">
          <li class="redit_head_icon_item"><img src="~@/assets/images/credit/advance_log.png" alt=""></li>
          <li class="redit_head_icon_item"><img src="~@/assets/images/credit/experian_ico.png" alt=""></li>
        </ul>
      </el-col>
    </el-row>
    <div class="credit_form_container">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="0px" class="demo-ruleForm">
        <el-form-item label="" prop="pan">
          <p class="label_text">Pan</p>
          <el-input v-model="form.pan" placeholder="Pan"></el-input>
        </el-form-item>
        <el-form-item label="" prop="firstName">
          <p class="label_text">First Name</p>
          <el-input v-model="form.firstName" placeholder="First Name"></el-input>
        </el-form-item>
        <el-form-item label="" prop="lastName">
          <p class="label_text">Last Name</p>
          <el-input v-model="form.lastName" placeholder="Last Name"></el-input>
        </el-form-item>
        <el-form-item label="" prop="phoneNumber" class="has_code_phone">
          <p class="label_text">Phone Number</p>
          <span class="code_state_phone">+91</span>
          <el-input v-model="form.phoneNumber" placeholder="Phone Number">
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="gender">
          <p class="label_text">Gender</p>
          <el-select class="credit_form_gender" popper-class="credit_form_option" placeholder="Gender" v-model="form.gender">
            <el-option
              v-for="item in genderOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="state">
          <p class="label_text">State</p>
          <el-select class="credit_form_state" popper-class="credit_form_option" ref="select" @hook:mounted="cancalReadOnly" @visible-change="cancalReadOnly" v-model="form.state" filterable placeholder="State">
            <el-option
              v-for="item in stateOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="email">
          <p class="label_text">Email</p>
          <el-input v-model="form.email" placeholder="Email"></el-input>
        </el-form-item>
        <el-form-item label="" prop="city">
          <p class="label_text">City</p>
          <el-input v-model="form.city" placeholder="City"></el-input>
        </el-form-item>
        <el-form-item label="" prop="pincode">
          <p class="label_text">Pincode</p>
          <el-input v-model="form.pincode" placeholder="Pincode"></el-input>
        </el-form-item>
        <el-form-item label="" prop="birthday" class="credit_form_birthday">
          <p class="label_text">Birthday</p>
          <el-date-picker 
            class="credit_form_birthday"
            v-model="form.birthday"
            type="date"
            :picker-options="datePickerOpt"
            align="left"
            :clearable="false"
            popper-class="credit_form_birthday_popper"
            :editable="false"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            placeholder="Birthday">
          </el-date-picker>
        </el-form-item>
        <div class="credit_form_agreement">
          <div class="credit_form_agreement_btn"><el-checkbox v-model="agreementChecked"></el-checkbox></div>
          <p class="credit_form_agreement_text"> I hereby consent to Advance AI being appointed as your authorized representative to receive my Credit Information from Experian for the purpose of Loan Application. I accept all the <span class="credit_form_agreement_text_btn" @click="showTermsConditionsHandle">Terms and Conditions</span>.</p>
        </div>
        <div>
          <el-button class="credit_form_proceed" type="primary" @click="onSubmit" :loading="proceedLoading">Proceed</el-button>
        </div>
      </el-form>
    </div>
    <terms-and-conditions ref="dialog" :propsTermsAndConditionsDescription="termsAndConditionsDescription"></terms-and-conditions>
  </div>
</template>

<script>
import {stateOptionsPackag} from './tool/stateOptionsPackag'
import termsAndConditions from './tool/termsAndConditions'
// import { sentAgreement } from '@/lib/credit/index'
import { agreement, agreementOtp } from '@/api/modules/credit'
export default {
  name: "credit",
  data () {
    const validatePan = (rule, value, callback) => {
      const reg = /^[a-zA-Z0-9]*$/
      const msg = 'Invalid Pan.'
      if (!reg.test(value)) {
        callback(new Error(msg))
      } else {
        callback()
      }
    }
    const validatePhoneNumber = (rule, value, callback) => {
      const reg = /^\d{10}$/
      const msg = 'Must be a 10 digit number.'
      // value = value.trim()
      // this.form.phoneNumber = value
      if (!reg.test(value)) {
        callback(new Error(msg))
      } else {
        callback()
      }
    }
    const validateFirstName = (rule, value, callback) => {
      const reg = /^[a-zA-Z\s]{1,26}$/
      const msg = 'Invalid name.'
      if (!reg.test(value)) {
        callback(new Error(msg))
      } else {
        callback()
      }
    }
    const validateLastName = (rule, value, callback) => {
      const reg = /^[a-zA-Z\s]{1,26}$/
      const msg = 'Invalid name.'
      if (!reg.test(value)) {
        callback(new Error(msg))
      } else {
        callback()
      }
    }
    const validateEmail = (rule, value, callback) => {
      const reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      const msg = 'Invalid Email.'
      if (!reg.test(value)) {
        callback(new Error(msg))
      } else {
        callback()
      }
    }
    return {
      agreementChecked: false,
      proceedLoading: false,
      termsAndConditionsDescription: '',
      form: {
        pan: '',
        firstName: '',
        lastName: '',
        phoneNumber: null,
        gender: '',
        birthday: '',
        email: '',
        state: '',
        city: '',
        pincode: ''
      },
      genderOptions: [{
        value: 'MALE',
        label: 'Male'
      }, {
        value: 'FEMALE',
        label: 'Female'
      }, {
        value: 'TRANSGENDER',
        label: 'Other'
      }],
      stateOptions: stateOptionsPackag,
      datePickerOpt: {
        disabledDate(time) {
          return time.getTime() > new Date().getTime()
        },
      },
      rules: {
        pan: [
          { required: true, message: 'Required field.', trigger: 'blur' },
          { validator: validatePan, trigger: 'blur' }
        ],
        firstName: [
          { required: true, message: 'Required field.', trigger: 'blur' },
          { validator: validateFirstName, trigger: 'blur' }
        ],
        lastName: [
          { required: true, message: 'Required field.', trigger: 'blur' },
          { validator: validateLastName, trigger: 'blur' }
        ],
        phoneNumber: [
          { required: true, message: 'Required field.', trigger: 'blur' },
          { validator: validatePhoneNumber, trigger: 'blur' }
        ],
        birthday: [
          { required: true, message: 'Required field.', trigger: 'blur' },
        ],
        gender: [
          { required: true, message: 'Required field.', trigger: 'change' }
        ],
        state: [
          { required: true, message: 'Required field.', trigger: 'change' }
        ],
        email: [
          { required: true, message: 'Required field.', trigger: 'blur' },
          { validator: validateEmail, trigger: 'blur' }
        ],
        city: [
          { required: true, message: 'Required field.', trigger: 'blur' },
          { max: 40, message: 'Too long', trigger: 'blur' }
        ],
        pincode: [
          { required: true, message: 'Required field.', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this._getAgreement()
  },
  mounted() {
    // const url = "http://www.baidu.com?a=1&b=张三&c=aaa"
    // this.sourceUrl = encodeURI(url)
    // console.log(encodeURI(url))
    // this.sourceUrl = "http://www.baidu.com?a=1&b=张三&c=aaa"
  },
  methods: {
    cancalReadOnly(onOff) {
      this.$nextTick(() => {
        if (!onOff) {
          const {select} = this.$refs
          const input = select.$el.querySelector('.el-input__inner')
          input.removeAttribute('readonly')
        }
      })
    },
    showTermsConditionsHandle() {
      if (this.termsAndConditionsDescription.length > 0) {
        this.$refs.dialog.open()
      } else {
        this._getAgreement()
      }
    },
    onSubmit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if (this.agreementChecked) {
            this.proceedLoading = true
            this._subGenerateOtp()
          } else {
            this.$message({message: "Need agree the terms to continue.", type: 'error', customClass: 'message_box'})
          }
          console.log('submit!')
        } else {
          console.log('error submit!!')
          return false;
        }
      })
    },
    async _getAgreement() {
      try {
        const { data } = await agreement()
        if (data.code !== 'SUCCESS') {
          this.$message({message: data.message, type: 'error', customClass: 'message_box'})
        } else {
          this.termsAndConditionsDescription = data.data || ''
        }
      } catch (e) {
        this.$message({message: e.message, type: 'error', customClass: 'message_box'})
      } finally {
        this.proceedLoading = false
      }
    },
    async _subGenerateOtp() {
      const params = {
        pan: this.form.pan,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        phoneNumber: `+91${this.form.phoneNumber}`,
        gender: this.form.gender,
        birthday: this.form.birthday,
        email: this.form.email,
        state: this.form.state,
        city: this.form.city,
        pincode: this.form.pincode,
      }
      try {
        const { data } = await agreementOtp(params)
        if (data.code !== 'SUCCESS') {
          this.$message({message: data.message, type: 'error', customClass: 'message_box'})
        } else {
          if (data.data && (data.data.txId || data.data.txId == 0)) {
            // let sourceUrl = this.sourceUrl
            // sourceUrl = encodeURIComponent(sourceUrl)
            this.$router.push({name: 'creditValidation', query: {txId: data.data.txId, token: this.token, sourceUrl: this.sourceUrl}})
          }
        }
      } catch (e) {
        this.$message({message: e.message, type: 'error', customClass: 'message_box'})
      } finally {
        this.proceedLoading = false
      }
    }
  },
  computed: {
    token() {
      return this.$route.query.token
    },
    sourceUrl() {
      return this.$route.query.sourceUrl
    }
  },
  components: {
    termsAndConditions
  },
}
</script>

<style lang="scss">
.credit_form_container{
  .el-form-item.is-success .el-input__inner, .el-form-item.is-success .el-input__inner:focus, .el-form-item.is-success .el-textarea__inner, .el-form-item.is-success .el-textarea__inner:focus{
    border-color: green;
  }
  .el-form-item{
    margin-bottom: 30px;
  }
  .el-input.is-active .el-input__inner, .el-input__inner:focus, .el-textarea__inner:focus, .el-select .el-input.is-focus .el-input__inner{
    border-color: #55C265;
  }
  .credit_form_agreement_btn{
    .el-checkbox{
      font-size: 0px;
      width: 12px;
      height: 12px;
    }
    .el-checkbox__inner{
      width: 12px;
      height: 12px;
      box-sizing: border-box;
    }
    .el-checkbox__inner::after{
      position: absolute;
      top: 0px;
      left: 3px;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
      background-color: #55C265;
      border-color: #55c265;
    }
    .el-checkbox__inner:hover{
      border: 1px solid #DCDFE6;
    }
  }
  .el-form-item__content{
    position: relative;
    top: 0;
    left: 0;
  }
  .has_code_phone{
    .code_state_phone{
      position: absolute;
      top: 11px;
      left: 12px;
      z-index: 2;
      line-height: 20px;
      font-weight: 700;
    }
    .el-input__inner{
      padding-left: 40px;
    }
  }
  .el-input__inner{
    color: #000000;
    font-weight: 700;
  }
}
.credit_form_birthday_popper{
  .el-date-table td.current:not(.disabled) span{
    background-color: #55C265;
  }
  .el-date-table td.today span, .el-picker-panel__icon-btn:hover{
    color: #55C265;
  }
}
.credit_form_option{
  .el-select-dropdown__item.selected{
    color: #55C265;
  }
}
</style>
<style lang="scss" scoped>
  .credit_wrap {
    background: #F8F8F8;
    padding: 72px 14px 100px;
    .credit_head_container{
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      padding: 13px 17px 0;
      background: #F8F8F8;
      .credit_head_title{
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        line-height: 24px;
      }
      .redit_head_icon_region{
        .redit_head_icon_item{
          width: 100px;
          height: auto;
          float: right;
          img{
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .credit_form_container{
      // background: yellow;
      padding-top: 15px;
      .label_text{
        position: absolute;
        top: -11px;
        left: 5px;
        z-index: 8;
        padding: 0 3px;
        color: #263238;
        font-size: 13px;
        background: #ffffff;
        border-radius: 4px;
        font-weight: 400;
      }
      .credit_form_agreement{
        position: relative;
        top: 0;
        left: 0;
        padding-left: 22px;
        box-sizing: border-box;
        margin-bottom: 22px;
        .credit_form_agreement_btn{
          position: absolute;
          top: 3px;
          left: 0;
          width: 15px;
          height: 15px;
          font-size: 0;
        }
        .credit_form_agreement_text{
          font-size: 12px;
          font-weight: 700;
          line-height: 17px;
          color: #666666;
          font-weight: bold;
          .credit_form_agreement_text_btn{
            color: #55C265;
          }
        }
      }
      .credit_form_gender, .credit_form_birthday, .credit_form_state, .credit_form_proceed{
        width: 100%;
      }
      .credit_form_birthday{
        &.el-form-item{
          margin-bottom: 22px;
        }
      }
      .credit_form_proceed{
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
        border-radius: 25px;
        border: 0px transparent solid;
        border: none;
        background: -webkit-linear-gradient(92.22deg, #5DD36E 8.52%, #30B043 99.3%);
        /* Safari 5.1 - 6.0*/
        background: -o-linear-gradient(92.22deg, #5DD36E 8.52%, #30B043 99.3%);
        /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(92.22deg, #5DD36E 8.52%, #30B043 99.3%);
        /* Firefox 3.6 - 15 */
        background: linear-gradient(92.22deg, #5DD36E 8.52%, #30B043 99.3%);
      }
    }
  }

</style>