const stateOptionsPackag = [
  {
      "value": "35",
      "label": "ANDAMAN and NICOBAR ISLANDS"
  },
  {
      "value": "28",
      "label": "ANDHRA PRADESH"
  },
  {
      "value": "12",
      "label": "ARUNACHAL PRADESH"
  },
  {
      "value": "18",
      "label": "ASSAM"
  },
  {
      "value": "10",
      "label": "BIHAR"
  },
  {
      "value": "04",
      "label": "CHANDIGARH"
  },
  {
      "value": "22",
      "label": "CHHATTISGARH"
  },
  {
      "value": "26",
      "label": "DADARA and NAGAR HAVELI"
  },
  {
      "value": "25",
      "label": "DAMAN and DIU"
  },
  {
      "value": "07",
      "label": "DELHI"
  },
  {
      "value": "30",
      "label": "GOA"
  },
  {
      "value": "24",
      "label": "GUJRAT"
  },
  {
      "value": "06",
      "label": "HARAYANA"
  },
  {
      "value": "02",
      "label": "HIMACHAL PRADESH"
  },
  {
      "value": "01",
      "label": "JAMMU and KASHMIR"
  },
  {
      "value": "20",
      "label": "JHARKHAND"
  },
  {
      "value": "29",
      "label": "KARNATAKA"
  },
  {
      "value": "32",
      "label": "KERALA"
  },
  {
      "value": "31",
      "label": "LAKSHADWEEP"
  },
  {
      "value": "23",
      "label": "MADHYA PRADESH"
  },
  {
      "value": "27",
      "label": "MAHARASHTRA"
  },
  {
      "value": "14",
      "label": "MANIPUR"
  },
  {
      "value": "17",
      "label": "MEGHALAYA"
  },
  {
      "value": "15",
      "label": "MIZORAM"
  },
  {
      "value": "13",
      "label": "NAGALAND"
  },
  {
      "value": "21",
      "label": "ORRISA"
  },
  {
      "value": "34",
      "label": "PONDICHERRY"
  },
  {
      "value": "03",
      "label": "PUNJAB"
  },
  {
      "value": "08",
      "label": "RAJASTHAN"
  },
  {
      "value": "11",
      "label": "SIKKIM"
  },
  {
      "value": "33",
      "label": "TAMIL NADU"
  },
  {
      "value": "36",
      "label": "TELANGANA"
  },
  {
      "value": "16",
      "label": "TRIPURA"
  },
  {
      "value": "09",
      "label": "UTTAR PRADESH"
  },
  {
      "value": "05",
      "label": "UTTRANCHAL"
  },
  {
      "value": "19",
      "label": "WEST BENGAL"
  }
]

export { stateOptionsPackag }