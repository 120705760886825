<template>
  <div class="credit_terms_conditions">
    <el-dialog
      title="Terms and Conditions"
      :visible.sync="dialogVisible"
      width="90%"
      :center="true"
      :close-on-click-modal="false">
      <div class="credit_terms_conditions_main">
        <p>{{propsTermsAndConditionsDescription}}</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    propsTermsAndConditionsDescription: {
      type: String,
      default: '-'
    }
  },
  data() {
    return {
      dialogVisible: false
    };
  },
   methods: {
    open() {
      this.dialogVisible = true
      return this.$nextTick()
    },
   }
}
</script>

<style lang="scss">
.credit_terms_conditions{
  .el-dialog{
    display: flex;
    flex-direction: column;
    margin:0 !important;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    border-radius: 7px;
    .el-dialog__header{
      // text-align: center;
      font-size: 15px;
      line-height: 28px;
      color: #333333;
      font-weight: 600;
      padding: 20px 15px 0px;
    }
    .el-dialog__body{
      padding: 16px 15px;
      font-size: 13px;
      color: #999999;
      font-weight: 400;
      line-height: 19px;
    }
    .el-dialog .el-dialog__body{
      flex:1;
      overflow: auto;
    }
  }
}
</style>
<style lang="scss" scoped>
  .credit_terms_conditions_main{
    max-height: 410px;
    min-height: 100px;
    overflow-y: scroll;
  }
</style>